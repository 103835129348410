import React, { FunctionComponent, useState } from "react";
import SocialCardMedia from "./SocialCardMedia";
import SocialCardShareToolbar from "./SocialCardShareToolbar";
import {FiltersContext} from "../../service/filters";

import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Tooltip,
    Typography,
    useTheme
} from "@material-ui/core";
import { ShareButton, TranslateContentButton, VisitSourceButton } from "../button";
import { SocialItem } from "../../service/data";
import { getSocialIcon } from "./socialUtils";
import { useSocialCardStyles } from "./useSocialCardStyles";
import { useLocale, useTranslate } from "../../service/translation";
import classNames from "classnames";
import {apiProvider} from "../../service/data/apiProvider";
import { Link } from "react-router-dom";
import { string } from "prop-types";

interface SocialCardProps {
    record: SocialItem;
}

const SocialCard: FunctionComponent<SocialCardProps> = (props: SocialCardProps) => {
    const classes = useSocialCardStyles();
    const translate = useTranslate();
    const [toolbarCollapsed, setToolbarCollapsed] = useState(true);
    const { record } = props;
    const { content, mep, media, postedAt,timeAgo, source } = record;
    const { avatar, country, firstName, lastName, politicalGroup} = mep;
    const [translation,setTranslation] = React.useState('');
    const [classesTranslate, setClassesTranslate] = useState('');
    const [classesShare, setClassesShare] = useState('');
    const [translateText,setTranslateText] = useState(translate("newshub.action.translateContent"));
    const [filters,setFilters] = React.useContext(FiltersContext);

    var componentTranslation:any = React.useRef(null);

    const highlightRegExp = () => {
        let reg:string = filters.searchBar.split(' ').filter(item => item.length > 0).join('|');
        return new RegExp(reg,'ig');
    }

    const regexp = React.useRef(highlightRegExp());

    React.useEffect(() => {
        regexp.current = highlightRegExp();
    },[filters.searchBar]);

    React.useEffect( () => {
        moveToViewCompleted();
        componentTranslation.current.focus();
    },[translation]);

    const formatter = new Intl.DateTimeFormat('en-GB', {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    });

    const handleToolbarToggle = () => {
        let colapsed = ! toolbarCollapsed;
        let newclas = colapsed? '' : classes.active;
        setToolbarCollapsed(colapsed);
        setClassesShare(newclas);

    };


    const moveToViewCompleted = () => {
        if(translation.length < 1) return;
        var cardBottom = componentTranslation.current.parentElement.parentElement.getBoundingClientRect().bottom;
        var offset = window.innerHeight - cardBottom;
        if(offset > 0 ) return;
        document.documentElement.scrollTop -= offset;
    };


    const handleToolbarToggleKeyboard = () => setToolbarCollapsed(false);
    const handleTranslate = () => {
        if(translation.length === 0){
            apiProvider.getTranslation('' + record.id)
            .then(response => {
                setTranslation(response.content);
                setClassesTranslate(classes.active);
                setTranslateText(translate("newshub.action.hideTranslation"));

            }).catch(e => {});
        }else {
            setTranslateText(translate("newshub.action.translateContent"));
            setTranslation('');
            setClassesTranslate('');

        }
    };

    const highlightContent = (text:string):string => {
        if(filters.searchBar.length === 0) return text;
        let a1:string[] = text.split('<');
        for(let i  in a1 ){
            let a2:string[] = a1[i].split('>');
            a2 = a2.map((currentValue:string,index:number):string => {
                if(index === 0) return currentValue;
                return currentValue.replace(regexp.current, (item:string,i:number) => {
                    return '<span style="background-color:#ead183">'+item+'</span>';
                 });
            });
            a1[i] = a2.join('>');
        }
        return a1.join('<');
    };




    return (
        <Card className={classNames(classes.root, 'masonryItem')} aria-live="polite">
            <Box className={classes.header}>
                <Box className={classes.avatar}>
                    <Link to={`/contributors/${mep.id}`} style={{ textDecoration: 'none'}}>

                        <Avatar className={classes.avatarImage + (mep.type === "mep" ? " codict-avatar" : " not-mep") } src={avatar} alt={lastName}>
                            {firstName.charAt(0).toUpperCase()}
                            {lastName.charAt(0).toUpperCase()}
                        </Avatar>
                    </Link>
                </Box>
                <Box className={classes.content}>
                    <Link to={`/contributors/${mep.id}`} style={{ display: 'block', textDecoration: 'none'}}>
                        <Typography variant="h2" component="p">
                        <div className={classes.cardText} dangerouslySetInnerHTML={{ __html: highlightContent(`${firstName} ${lastName}`) }} />

                        </Typography>
                    </Link>
                    <Box className="preWrap">
                        { politicalGroup.party !== '' &&
                        <a href={`/contributors/${politicalGroup.id}`} title={politicalGroup.party} style={{ textDecoration: 'none'}}/* className={"xs_noevents"}*/>
                            <Typography variant="subtitle2" display="inline" component="p">
                                <strong>
                                {politicalGroup.party}
                                </strong>
                            </Typography>
                        </a>
                        }
                        <Typography variant="subtitle2" display="inline" component="p">
                            {country? `, ${country}` : ''}
                        </Typography>
                    </Box>
                    <Typography variant="subtitle2" component="p">
                        {translate("social.action." + source)}
                    </Typography>
                </Box>
                <Box className={classes.source}>
                    <Tooltip title={formatter.format(postedAt)}>
                        <Typography variant="subtitle2" component="p">
                            {timeAgo}
                        </Typography>
                    </Tooltip>
                    <Avatar className={classes.sourceIcon}>
                        {getSocialIcon(source)}
                    </Avatar>
                </Box>
            </Box>
            <CardContent className={classes.contentContainer}>
                <Typography variant="body1" component="div">
                    <div className={classes.cardText} dangerouslySetInnerHTML={{ __html: highlightContent(content) }} />
                </Typography>
            </CardContent>
            <Box className={classes.mediaContainer}>
                <SocialCardMedia
                    className={classes.media}
                    classes={classes}
                    media={media}
                    title={translate("social.message.postedBy", { ...mep })}
                    source={record.sourceUrl}
                />
            </Box>
            <Typography variant="body1" component="div">
                <div
                    ref={node => componentTranslation.current = node}
                    className={classes.cardTextTranslation}
                    dangerouslySetInnerHTML={{ __html: translation }}
                />
            </Typography>
            <CardActions className={classes.cardActions}>
                <TranslateContentButton
                    className={classesTranslate}
                    onClick={handleTranslate}
                    aria-label={translateText}
                />
                <Button
                    aria-label={translate("newshub.action.visitSource")}
                    color="primary"
                    size="small"
                    href={record.sourceUrl}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    {...props}
                >
                    {translate("newshub.action.visitSource")}
                </Button>

                <ShareButton
                    className={classesShare}
                    onClick={handleToolbarToggle}
                    onKeyDown={handleToolbarToggleKeyboard}
                />
            </CardActions>
            <CardMedia className={classes.cardMedia}>
                <SocialCardShareToolbar
                    classes={classes}
                    collapsed={toolbarCollapsed}
                    record={record}
                />
            </CardMedia>
        </Card>
    );
};

export default SocialCard;
