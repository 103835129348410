import React, {useEffect,useRef } from 'react';
import { ResponsiveLine } from "@nivo/line";
import {createRangerSlice} from "./RangeSlider";
import {FiltersContext} from "../../service/filters";
import {useTheme} from "@material-ui/core";

type Range = {
    begin:number;
    end: number;
}

type Period = {
    from: string,
    to: string
}

interface TimelineChartProps {
    data: any;
    maxValue?:number;
    changeSelected?: (begin:number,end:number,active:boolean)  => void
    initialBegin?:number,
    initialEnd?:number,
}



const TimelineChart = (props: TimelineChartProps) => {
    const { data ,changeSelected,maxValue,initialBegin,initialEnd} = props;
    const [itemFilters,setItemFilters] = React.useContext(FiltersContext);
    const [rangeClass, setRangeClass] = React.useState('');
    var slider:any = useRef(null);

    useEffect(() => {
        if(!slider.current) {   // Actualiza el título del documento usando la API del navegador    document.title = `You clicked ${count} times`;  });
            slider.current = createRangerSlice(0, 100, "#slider-container");
            slider.current.range(itemFilters.timeline.begin,itemFilters.timeline.end);
            slider.current.onChange(function(newRange:Range){
                if(typeof changeSelected === 'function'){
                    let isActive = false;
                    if(newRange.begin  !== initialBegin || newRange.end !== initialEnd){
                        isActive = true;
                        setRangeClass('active');
                    }
                    changeSelected(newRange.begin, newRange.end, isActive);
                }
            });
        }
        slider.current.range(initialBegin,initialEnd);
    },[]);

    React.useEffect(() => {
        switch(itemFilters.crossActions.timeLineEvent.action){
            
            case 'resetRange':
                setRangeClass('');
                slider.current.range(initialBegin,initialEnd);
                break;
        }
    },[itemFilters.crossActions.timeLineEvent]);


    const theme = useTheme();
    return (
       <>
        <div className="timeLineSliderContainer" >
            <div id="slider-container" className={rangeClass} />
                <ResponsiveLine
                    data={data}
                    colors={theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.primary.main}
                    margin={{ top: 0, right: 15, bottom: 50, left: 15 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 0, max: maxValue, stacked: true, reverse: false }}
                    yFormat=" >-.2f"
                    curve="natural"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 14,
                    }}
                    theme={{
                        axis: {
                            ticks: {
                                text: {
                                    fill: theme.palette.type === "light" ? theme.palette.text.hint : theme.palette.text.hint
                                }
                            }
                        },
                        grid: {
                            line: {
                                stroke: theme.palette.type === "light" ? "#CCC" : theme.palette.text.hint,
                            }
                        }
                    }}
                    axisLeft={null}
                    enableGridX={true}
                    enableGridY={false}
                    lineWidth={4}
                    enableCrosshair={false}
                    enablePoints={false}
                    useMesh={false}
                    legends={[]} 
                    onClick= {(point,event) => {console.log('POINT',point); }}
                    />

            </div>
        </>
    );
};

export default TimelineChart;
